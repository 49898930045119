<template>
  <div class="row">
    <div class="left col-12 col-md-6">
      <h5>{{ locale.preview }}</h5>
      <video id="preview" :ref="'prev'" autoplay muted></video>
      <div id="startButton" class="button">
        <p @click="startPrev">{{ locale.startpreview }}</p>
        <img
          :src="require('../assets/cam.webp')"
          width="50px"
          alt=""
          style="cursor: pointer"
          @click="startRec"
        />
        <img
          v-if="loading"
          :src="require('../assets/loading.gif')"
          width="50px"
          alt=""
          style="cursor: pointer"
        />
      </div>
    </div>
    <div class="right col-12 col-md-6">
      <div id="stopButton" class="button"></div>
      <h5>{{ locale.review }}</h5>
      <video id="recording" :ref="'recording'"></video>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
// @ is an alias to /src
import { LocaleArabic, LocaleEnglish } from "../scripts/Locale";

export default {
  name: "CameraRecorder",
  components: {},
  props: { camRecTime: Number, constraints: Object, localCount: String },
  data() {
    return {
      locale: null,
      loading: false,
      recordingTimeMS: this.camRecTime,
      localStream: null,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },

    log(msg) {
      console.error(msg);
      this.loading = false;
    },
    wait(delayInMS) {
      return new Promise((resolve) => setTimeout(resolve, delayInMS));
    },
    startRecording(stream, lengthInMS) {
      if (
        this.constraints.obj.clips &&
        this.constraints.obj.clips.length +
          Number(
            document.getElementById(`${this.localCount}`).children.length
          ) >=
          this.constraints.obj.maxClip
      ) {
        console.log(this.constraints.obj.clips);
        this.stop(this.$refs.prev.srcObject);

        alert(this.locale.limitsreached);
        return;
      }
      let recorder = new MediaRecorder(stream);
      let data = [];

      recorder.ondataavailable = (event) => data.push(event.data);
      recorder.start();
      // console.log(recorder.state + " for " + lengthInMS / 1000 + " seconds...");

      let stopped = new Promise((resolve, reject) => {
        recorder.onstop = resolve;
        recorder.onerror = (event) => reject(event.name);
      });

      let recorded = this.wait(lengthInMS).then(
        () => recorder.state == "recording" && recorder.stop()
      );

      return Promise.all([stopped, recorded]).then(() => data);
    },
    startRec() {
      console.log("startRec");
      if (
        this.constraints.obj.clips &&
        this.constraints.obj.clips.length >= this.constraints.obj.maxClip
      ) {
        this.stop(this.$refs.prev.srcObject);

        alert(this.locale.limitsreached);
        return;
      }
      this.loading = true;
      let self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: this.constraints.width,
            height: this.constraints.height,
          },
          audio: false,
        })
        .then((stream) => {
          this.$refs.prev.srcObject = stream;
          this.$refs.prev.captureStream =
            this.$refs.prev.captureStream || this.$refs.prev.mozCaptureStream;
          return new Promise(
            (resolve) => (this.$refs.prev.onplaying = resolve)
          );
        })
        .then(() =>
          this.startRecording(
            this.$refs.prev.captureStream(),
            this.recordingTimeMS
          )
        )
        .then((recordedChunks) => {
          let recordedBlob = new Blob(recordedChunks, { type: "video/webm" });
          this.$refs.recording.src = URL.createObjectURL(recordedBlob);
          var reader = new FileReader();
          reader.readAsDataURL(recordedBlob);
          reader.onloadend = function() {
            var base64data = reader.result;
            if (recordedBlob.size > 0) {
              self.$emit(
                "base64String",
                base64data.replace("data:video/webm;base64,", "")
              );
              self.stop(self.$refs.prev.srcObject);
            } else {
              self.$emit("base64String", false);
            }
            self.loading = false;
          };
          // console.log(recordedBlob);
          // console.log(
          //   "Successfully recorded " +
          //     recordedBlob.size +
          //     " bytes of " +
          //     recordedBlob.type +
          //     " media."
          // );
        })
        .catch(this.log);
    },
    stop(stream) {
      stream.getTracks().forEach((track) => track.stop());
    },
    _base64ToArrayBuffer(base64) {
      //not used but could be useful

      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    startPrev() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: this.constraints.width,
            height: this.constraints.height,
          },
          audio: false,
        })
        .then((stream) => {
          this.$refs.prev.srcObject = stream;
          this.$refs.prev.captureStream =
            this.$refs.prev.captureStream || this.$refs.prev.mozCaptureStream;
          return new Promise(
            (resolve) => (this.$refs.prev.onplaying = resolve)
          );
        })
        .then(() => {
          console.log("cam initialized");
        });
    },
  },
  created() {
    this.checkLocal();
  },
};
</script>

<style lang="scss" scoped>
.left {
  float: left;
}
video {
  border: 1px solid grey;
  width: 250px;
  height: 188px;
}
</style>
