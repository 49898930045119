<template>
  <div>
    <ControlNavbar/>
    <a-alert
        v-if="errorConatiner.status"
        :message="errorConatiner.message"
        :description="errorConatiner.description"
        :type="errorConatiner.type"
        closable
        @close="onClose"
    />
    <div
        class="profile"
        v-if="pageData.available && pageData.available.length > 0"
    >
      <h1 class="header" v-if="pageData.pageTitle">{{ pageData.pageTitle }}</h1>
      <!--      <profile-image />-->

      <br/>
      <a-dropdown v-if="pageData.available && pageData.available.length > 0">
        <a-menu slot="overlay" @click="changeTypeToShow">
          <a-menu-item v-for="type in pageData.available" :key="type.typeId">
            {{ type.typeLabel }}
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          {{ pageData.typeTitle }}
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>

      <div v-for="type in pageData.available" :key="type.typeId">
        <div v-if="type.typeId == type_to_show">
          <h2>{{ type.typeLabel }}</h2>
          <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
              @submit="handleSubmit"
          >
            <div v-for="(field, index) in type.fields" :key="index">
              <h5 v-if="field.type == 'header'">{{ field.label }}</h5>
              <!-- TEXT INPUT -->
              <div v-if="field.type == 'text'">
                <a-form-item :label="field.label">
                  <a-input
                      allow-clear
                      v-decorator="[
                      `${field.id}`,
                      {
                        initialValue: field.value,
                        rules: [
                          {
                            required: field.required,
                            message: locale.emptyFieldsWarning,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <!-- END OF TEXT INPUT -->
              <div v-if="field.type == 'stateCity'">
                <a-form-item :label="field.label" has-feedback>
                  <a-select
                      v-decorator="[
                      field.id,
                      {
                        rules: [
                          {
                            required: field.required,
                            message: `${locale.emptyFieldsWarning}`,
                          },
                        ],
                      },
                    ]"
                      :placeholder="field.label"
                      @change="selectState"
                  >
                    <a-select-option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                    >
                      {{ state.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="field.label2" has-feedback>
                  <a-select
                      v-decorator="[
                      field.id2,
                      {
                        rules: [
                          {
                            required: field.required2,
                            message: `${locale.emptyFieldsWarning}`,
                          },
                        ],
                      },
                    ]"
                      :placeholder="field.label2"
                      @change="selectCity"
                  >
                    <a-select-option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                    >
                      {{ city.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div v-if="field.type == 'image'">
                <a-form-item :label="field.label" :extra="field.maxImage">
                  <a-upload
                      @change="
                      (e) => {
                        // update filelist on change and on every time after slicing take the files in flielist and put them in an array with their id.
                        updateFileList(e, field.id, field.maxImage);
                        getBase64(e.file, field.id);
                      }
                    "
                      list-type="picture"
                      class="upload-list-inline"
                      :customRequest="uploadfiles"
                      :multiple="true"
                      :file-list="fileList[field.id]"
                      v-decorator="[
                      field.id,
                      {
                        rules: [
                          {
                            required: !(field.images.length > 0),
                            message: ` ${locale.emptyFieldsWarning}`,
                          },
                        ],
                        valuePropName: field.id,
                      },
                    ]"
                  >
                    <a-button>
                      <a-icon type="upload"/>
                      {{ field.label }}
                    </a-button>
                  </a-upload>
                  <div :class="`row `" :id="`image-upload-${field.id}`">
                    <div
                        class="previewThumb col"
                        v-for="c in field.images"
                        :id="`imgContainer${c.id}`"
                        :key="c.id"
                    >
                      <img
                          width="150px"
                          height="115px"
                          :src="getImage(c.id, 'thumb')"
                          @click="largify(c.id)"
                          :id="c.id"
                      />
                      <a-icon
                          style="transform: scale(1.25); padding: 1px"
                          type="delete"
                          class="delete"
                          @click="deleteUploadedImage(c.id, field.id)"
                      />
                    </div>
                  </div>
                </a-form-item>
              </div>
              <div v-else-if="field.type == 'dropdown'">
                <a-form-item :label="field.label" has-feedback>
                  <a-select
                      v-decorator="[
                      field.id,
                      {
                        initialValue: JSON.parse(field.value),
                        rules: [
                          {
                            required: field.required,
                            message: locale.emptyFieldsWarning,
                          },
                        ],
                      },
                    ]"
                      :placeholder="field.label"
                  >
                    <a-select-option
                        v-for="option in field.options"
                        :key="option.id"
                        :value="option.id"
                    >
                      {{ option.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div v-else-if="field.type == 'listdropdown'">
                <a-form-item :label="field.label" has-feedback>
                  <a-select
                      v-decorator="[
                      field.id,
                      {
                        initialValue: field.value,
                        rules: [
                          {
                            required: field.required,
                            message: locale.emptyFieldsWarning,
                          },
                        ],
                      },
                    ]"
                      :placeholder="field.hint"
                  >
                    <a-select-option
                        v-for="option in field.options"
                        :key="option.id"
                        :value="option.id"
                    >
                      {{ option.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item
                    v-for="(f, index) in field.fields[getVal(field.id) || temp]"
                    :key="index"
                    :label="f.label"
                    has-feedback
                >
                  <CameraRecorder
                      @base64String="saveClip($event, f)"
                      :camRecTime="f.clipLength"
                      :constraints="{
                      width: 1920,
                      height: 1080,
                      obj: f,
                      localCount: `local${f.id}`,
                    }"
                  />
                  <div :id="`local${f.id}`">
                    <div
                        class="previewThumb"
                        v-for="c in f.clips"
                        :id="`imgContainer${c.id}`"
                        :key="c.id"
                    >
                      <img
                          width="150px"
                          height="115px"
                          :src="getImage(c.id, 'thumb')"
                          @click="largify(c.id)"
                          :id="c.id"
                      />
                      <a-icon
                          style="transform: scale(1.25); padding: 1px"
                          type="delete"
                          class="delete"
                          @click="deleteUploadedImage(c.id, f.id)"
                      />
                      <a-tooltip>
                        <template slot="title"> {{ c.status.text }}</template>
                        <img
                            :src="c.status.image"
                            style="
                            width: 25px;
                            position: relative;
                            top: 40px;
                            right: 43px;
                          "
                            alt=""
                        />
                      </a-tooltip>
                    </div>
                  </div>

                  <div class="d-flex justify-content-start">
                    <div
                        class="d-flex justify-content-start"
                        v-for="(clip, index) in clips"
                        :key="index"
                    >
                      <div v-if="clip.id == f.id" class="previewThumb">
                        <video
                            class="thumbnail-videos"
                            :src="`data:video/webm;base64,${clips[index].data}`"
                            width="150px"
                            height="115px"
                            style="margin-right: 10px"
                        ></video>
                        <a-icon
                            style="
                            transform: scale(1.25);
                            padding: 1px;
                            height: fit-content;
                          "
                            type="delete"
                            class="deleteLocal"
                            @click="deleteTakenImage(clip.id, clip.data)"
                        />
                      </div>
                    </div>
                  </div>
                </a-form-item>
              </div>
              <div v-else-if="field.type == 'clip'">
                <a-form-item :label="field.label" has-feedback>
                  <CameraRecorder
                      @base64String="saveClip($event, field)"
                      :camRecTime="field.clipLength"
                      :constraints="{
                      width: 1920,
                      height: 1080,
                      obj: field,
                      localCount: `local${field.id}`,
                    }"
                  />
                  <div :id="`local${field.id}`">
                    <div
                        class="previewThumb"
                        v-for="c in field.clips"
                        :id="`imgContainer${c.id}`"
                        :key="c.id"
                    >
                      <img
                          width="150px"
                          height="115px"
                          :src="getImage(c.id, 'thumb')"
                          @click="largify(c.id)"
                          :id="c.id"
                      />
                      <a-icon
                          style="transform: scale(1.25); padding: 1px"
                          type="delete"
                          class="delete"
                          @click="deleteUploadedImage(c.id, field.id)"
                      />
                      <a-tooltip>
                        <template slot="title"> {{ c.status.text }}</template>
                        <img
                            :src="c.status.image"
                            style="
                            width: 25px;
                            position: relative;
                            top: 40px;
                            right: 43px;
                          "
                            alt=""
                        />
                      </a-tooltip>
                    </div>
                  </div>

                  <div class="d-flex justify-content-start">
                    <div
                        class="d-flex justify-content-start"
                        v-for="(clip, index) in clips"
                        :key="index"
                    >
                      <div v-if="clip.id == field.id" class="previewThumb">
                        <video
                            class="thumbnail-videos"
                            :src="`data:video/webm;base64,${clips[index].data}`"
                            width="150px"
                            height="115px"
                            style="margin-right: 10px"
                        ></video>
                        <a-icon
                            style="
                            transform: scale(1.25);
                            padding: 1px;
                            height: fit-content;
                          "
                            type="delete"
                            class="deleteLocal"
                            @click="deleteTakenImage(clip.id, clip.data)"
                        />
                      </div>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
              <a-button type="danger" html-type="submit">
                {{ pageData.button }}
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>

      <!-- <button type="button" @click="submitInfo()">{{ pageData.button }}</button> -->
    </div>
    <div v-else class="loading">
      <img :src="require(`../assets/loading.gif`)" alt=""/>
    </div>
    <div class="loading" v-if="loading">
      <img
          :src="require('../assets/loading.gif')"
          width="30%"
          alt="LOADING..."
      />
    </div>
    <a-modal v-model="largifyVisible" :footer="null">
      <div style="margin: 3%">
        <img ref="largifyimg" src="" width="100%" alt=""/>
      </div>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import "bootstrap";
import ControlNavbar from "@/components/ControlNavbar";
import {API_ENDPOINTS} from "../scripts/request";
import CameraRecorder from "@/components/CameraRecorder";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Profile",
  components: {ControlNavbar, CameraRecorder},
  data() {
    return {
      locale: null,
      largifyVisible: false,
      loading: false,
      temp: null,
      clips: [],
      errorConatiner: {
        status: false,
        message: "",
        description: "",
        type: "error",
      },
      images: [],
      fileList: [],
      filesToUpload: [],
      tempImg: "",
      pageData: {},
      type_to_show: "",
      states: [{}],
      cities: [{}],
      selectedState: "",
      selectedCity: "",
      selectedImages: [],
      form: this.$form.createForm(this, {name: this.type_to_show}),
      backendDeletedImages: [], //used to store id's of the images that user chose to delete
    };
  },
  beforeMount() {
    this.checkLocal();
    this.getStatesCities();
  },
  methods: {
    info(title, message) {
      const h = this.$createElement;
      let self = this;
      this.$info({
        title: title,
        content: h("div", {}, [h("p", message)]),
        okText: this.locale.ok,
        onOk() {
          self.$router.push({
            name: "Home",
          });
        },
      });
    },

    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    deleteUploadedImage(imageID, fieldID) {
      console.log(imageID, fieldID);
      let self = this;
      this.$confirm({
        title: self.locale.delete_image_warning,
        okText: self.locale.yes,
        okType: "danger",
        cancelText: self.locale.cancel,
        onOk() {
          self.APIdeleteUploadedImage(imageID, fieldID);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    APIdeleteUploadedImage(imageID, fieldID) {
      console.log(imageID, fieldID);
      this.backendDeletedImages.push(imageID.toString());
      document.getElementById(`imgContainer${imageID}`).remove();
      // here i must add the image data in the deleted array that will be sent when the profile is saved
    },
    largify(id) {
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      // either full to get a large image or thumb to get a thumbnail of it
      this.loading = true;
      ax.get(`${this.$session.get("baseURI")}image/${id}/full`).then(
          (response) => {
            this.loading = false;
            let self = this;
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                  // convert image file to base64 string
                  self.largifyVisible = true;
                  setTimeout(function () {
                    self.$refs.largifyimg.src = reader.result;
                  }, 500);
                },
                false
            );
            reader.readAsDataURL(response.data);
          }
      );
    },
    getImage(id, type) {
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      // either full to get a large image or thumb to get a thumbnail of it
      if (type == "full") {
        ax.get(`${this.$session.get("baseURI")}image/${id}/full`).then(
            (response) => {
              // console.log(Buffer.from(response.data, "base64"));
              const reader = new FileReader();
              reader.addEventListener(
                  "load",
                  function () {
                    // convert image file to base64 string
                    if (document.getElementById(`${id}`))
                      document.getElementById(`${id}`).src = reader.result;
                  },
                  false
              );
              reader.readAsDataURL(response.data);
            }
        );
      } else {
        ax.get(`${this.$session.get("baseURI")}image/${id}/thumb`).then(
            (response) => {
              // console.log(Buffer.from(response.data, "base64"));
              const reader = new FileReader();
              reader.addEventListener(
                  "load",
                  function () {
                    // convert image file to base64 string
                    document.getElementById(`${id}`).src = reader.result;
                  },
                  false
              );
              reader.readAsDataURL(response.data);
            }
        );
      }
    },
    deleteTakenImage(id, data) {
      console.log(id, data);
      console.log(this.clips);
      let i = 0;
      this.clips.forEach((clip) => {
        if (clip.id == id && clip.data == data) {
          this.clips.splice(i, 1);
        }
        i++;
      });
    },
    getVal(id) {
      let self = this;
      setTimeout(function () {
        self.temp = document.getElementById(`${id}`).value;
      }, 1000);

      return null;
    },
    saveClip(data, field) {
      // takes base64 from camrecorder and binds it to it's field id
      console.log(field.id, "data");
      if (data) {
        console.log("starting to add image");
        let index = -1; //used to know the index of the picture to be replaced
        let flag = false;
        if (this.clips.length < field.maxClip) {
          for (let i = 0; i < this.clips.length; i++) {
            let clip = this.clips[i];
            if (clip.id == field.id && field.maxClip == 1) {
              flag = true;
              index = i;
              break;
            } else if (clip.id == field.id && field.maxClip > 1) {
              flag = false;
              index = -1;
            }
          }
          if (flag && index >= 0) {
            //replace
            console.log("switching: " + index);
            this.clips.splice(index, 1, {id: field.id, data: data});
          } else {
            console.log("pushing new");
            //push
            this.clips.push({id: field.id, data: data});
          }
        } else {
          alert(this.locale.limitsreached);
        }
      } else {
        alert(this.locale.picture_capture_error);
      }
    },
    checkApiResponse(result) {
      try {
        if (result.headers.sessioncounter) {
          this.$session.set("sessionCounter", result.headers.sessioncounter);
        }
        if (!result.data.status) {
          // error
          this.$message.error(result.data.dialog.message);
          console.error(`error in (${result.config.url})`, result);
          if (result.data.code === "auth.session_expired") {
            // log user out
            this.logout();
          }
        } else {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    onClose(e) {
      console.log(e);
      this.errorConatiner.status = false;
    },
    getBase64(file, id) {
      //creates base64 from an image and adds it to images array with their id wont be used on submit because using filelist is safer and easier
      file = file.originFileObj;
      let files64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
      files64.then((e) => {
        let cleanE = e.split("base64,")[1];
        this.images.push({id: id, data: cleanE});
      });
    },
    updateFileList(info, id, maxImage) {
      if (
          document.getElementById(`image-upload-${id}`).children.length ==
          maxImage
      ) {
        alert("Limits Exceeded");
        return;
      }
      // let self = this;
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      this.fileList[id] = fileList.slice(-maxImage);
      this.filesToUpload[id] = [];
      fileList.forEach((fileL) => {
        let file = fileL.originFileObj;
        let files64 = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
        files64.then((e) => {
          let cleanE = e.split("base64,")[1];
          this.filesToUpload[id].push({id: id, data: cleanE});
        });
      });
    },
    updateErrorContainer(status, message, description, type) {
      this.errorConatiner.status = status;
      this.errorConatiner.message = message;
      this.errorConatiner.description = description;
      this.errorConatiner.type = type;
    },
    addBase64ImagesForSubmission() {
      // check if the form used by selected type to show has any image fields:
      // if yes
      // take the id's of the fields
      //  take the filelist of this id,
      //  convert the images from file list to base 64
      // add them to a json for the new images to be submitted
      // return the array
      let resultingImages = [];
      for (let i = 0; i < this.pageData.available.length; i++) {
        if (this.pageData.available[i].typeId == this.type_to_show) {
          let fields = this.pageData.available[i].fields;
          fields.forEach((field) => {
            if (field.type && field.type === "image") {
              let images = this.fileList[field.id];
              images.forEach((img) => {
                let reader = new FileReader();
                reader.onload = (e) => {
                  let cleanData = e.target.result;
                  if (cleanData.includes("base64,")) {
                    cleanData = e.target.result.split("base64,")[1];
                  }
                  resultingImages.push({
                    id: field.id,
                    data: cleanData, // i need to make it wait for the result.
                  });
                };
                reader.readAsDataURL(img.originFileObj);
              });
            }
          });
        }
      }
      return resultingImages;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          // add the images in the form in base64 form
          let newImages = [];
          this.filesToUpload;
          this.clips.forEach((element) => {
            newImages.push(element);
          });

          // removing filelist from values bcz it's in newImages
          try {
            for (let i = 0; i < this.pageData.available.length; i++) {
              let available = this.pageData.available[i];
              if (available.typeId == this.type_to_show) {
                let fields = available.fields;
                for (let j = 0; j < fields.length; j++) {
                  let f = fields[j];
                  if (f.type == "image") {
                    if (this.filesToUpload[f.id]) {
                      this.filesToUpload[f.id].forEach((image) => {
                        newImages.push(image);
                      });
                    }
                    delete values[`${f.id}`];
                  }
                }
              }
            }
          } catch (error) {
            console.error(error);
          }

          // end removing filelist from values

          // sending the form to account/profile/update as POST
          console.log("new images: ", {
            //body
            accountTypeId: this.type_to_show,
            values: values,
            newImages: newImages,
            deletedImages: this.backendDeletedImages, // dont forget to change it id's of deleted images
          });
          this.loading = true;

          this.getAxios()
              .post(`${this.$session.get("baseURI")}account/profile/update`, {
                //body
                accountTypeId: this.type_to_show,
                values: values,
                newImages: newImages,
                deletedImages: this.backendDeletedImages, // dont forget to change it id's of deleted images
              })
              .then((result) => {
                if (this.checkApiRes(result, {
                  accountTypeId: this.type_to_show,
                  values: values,
                  newImages: newImages,
                  deletedImages: this.backendDeletedImages,
                }, "account/profile/update", "post")) {
                  this.loading = false;
                  console.log(result);
                  location.reload();
                } else {
                  this.loading = false;
                }
              });
        }
      });
    },
    uploadfiles({onSuccess, file}) {
      // this.getBase64(file)
      //   .then(() => {
      onSuccess(null, file);
      // })
      // .catch(() => {
      //   console.log("error");
      // });
    },
    selectState(value) {
      console.log(value);
      this.selectedState = value;
      this.states.forEach((state) => {
        if (state.id == value) {
          this.cities = state.cities;
        }
      });
    },
    selectCity(value) {
      console.log(value);
      this.selectedCity = value;
    },
    changeTypeToShow({key}) {
      this.type_to_show = key;
    },
    getStatesCities() {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}data/states`)
          .then((response) => {
            if (this.checkApiRes(response, null, "data/states", "get")) {
              this.states = response.data.data.states;
            }
          });
    },
    submitInfo() {
      var formToSubmit = {};
      let selectedTypeInfo = {};
      for (let i = 0; i < this.pageData.available.length; i++) {
        if (this.pageData.available[i].typeId === this.type_to_show) {
          selectedTypeInfo = this.pageData.available[i];
        }
      }
      selectedTypeInfo.fields.forEach((field) => {
        formToSubmit["accountTypeId"] = `${this.type_to_show}`;
        if (field.type == "text") {
          formToSubmit[`${field.id}`] = document.getElementById(
              `${this.type_to_show}${field.id}`
          ).value;
        } else if (field.type == "stateCity") {
          // get state and city chose from data()
          formToSubmit[`${field.id}`] = this.selectedState;
          formToSubmit[`${field.id2}`] = this.selectedCity;
        } else if (field.type == "image") {
          let reader = new FileReader();
          var file = document.getElementById(
              `${this.type_to_show}${field.id}`
          ).files;
          reader.onload = (e) => {
            formToSubmit[`${field.id}`] = e.target.result;
          };
          reader.readAsDataURL(file[0]);
        }
      });
      console.log(formToSubmit);
    },
    normFile(e) {
      return e;
      //   console.log("Upload event:", e);
      //   if (Array.isArray(e)) {
      //     return e;
      //   }
      //   return e.getBase64;
    },
  },
  computed: {
    getRefs: function () {
      console.log(this.$refs);
      return this.$refs[`dropdown`].value;
    },
  },
  mounted: function () {
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // check if this session is here and has data else call the api to get it back
    // console.log("profile", this.$session.get("profile_data"));
    let target = null;
    if (this.$route.params.target) {
      target = this.$route.params.target;
      this.$session.set("profile_target", target);
    } else {
      target = this.$session.get("profile_target");
    }
    this.getAxios()
        .post(`${API_ENDPOINTS.getProfileData}`, {target: target})
        .then((result) => {
          if (this.checkApiRes(result, {target: target}, API_ENDPOINTS.getProfileData, "post")) { // endpoint also doesnt matter it's not a purchase we can even put it as null
            if (result.data.dialog) {
              this.info(result.data.dialog.title, result.data.dialog.message);
            }
            console.log("profile", result.data);
            this.pageData = result.data.data;
            this.type_to_show = this.pageData.selectedTypeId;
            // saving servicesVersion
            this.$session.set(
                "servicesversion",
                this.$session.get("profile_data").headers.servicesversion
            );
            // select selected city and state if they have a value
            // also we need to get each image field it's images put them in
            // a list so they can be displayed before updating check antdv how
            // to show thumbnails.
          } else {
            console.log("hello");
          }
        });
  },
};
</script>

<style lang="scss" scoped>
.deleteLocal {
  position: relative;
  bottom: 110px;
  right: 30px;
  background: red;
  border-radius: 45px;
  cursor: pointer;
}

.previewThumb {
  text-align: start;
  margin-top: 3%;
  margin-bottom: 3%;

  img {
    border-radius: 15px;
    cursor: pointer;
  }

  video {
    border-radius: 15px;
    cursor: pointer;
  }

  .delete {
    position: relative;
    bottom: 48px;
    right: 20px;
    background: red;
    border-radius: 45px;
    cursor: pointer;
  }
}

// .statusText {
//   position: relative;
//   top: 0px;
//   right: 15px;
//   display: none;
// }
.previewThumb :hover + .statusText {
  display: inherit;
}

.header {
  padding: 20px;
  background: #e6e6e6;
  box-shadow: 7px 6px 5px grey;
}

.loading {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0%;
  z-index: 9;
  height: 100vh;

  img {
    position: relative;
    top: 40%;
    width: 10%;
    z-index: 10;
  }
}

.thumbnail-videos {
  margin-bottom: 10px;
}

.thumbnail-videos:hover {
  border: 1px solid red;
}
</style>
